import axios from 'axios'
import { SERVER_HOST } from './../common/portConfig'
var instance = axios.create({
    headers: {
        'Authorization': ''
    }
});
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = sessionStorage.getItem('Authorization');
        return config
    }, err => {
        return Promise.reject(err)
    });


// 美食
export const reqGetFoodsListPage = params => { return instance.get(`${SERVER_HOST}/admin/foods/page`, { params: params }) };
export const reqAddFoods = params => { return instance.post(`${SERVER_HOST}/admin/foods/add`, params) };
export const reqEditFoods = params => { return instance.post(`${SERVER_HOST}/admin/foods/edit`, params) };
export const reqDeleteFoods = params => { return instance.delete(`${SERVER_HOST}/admin/foods/delete/` + params.id, params) };
export const reqBatchDeleteFoods = params => { return instance.post(`${SERVER_HOST}/admin/foods/batchdelete/`, params) };
export const reqGetFoodsOne = params => { return instance.get(`${SERVER_HOST}/admin/foods/one`, { params: params }) };
//export const reqEditStatusFoods = params => { return instance.post(`${SERVER_HOST}/admin/foods/editStatus`, params) };
export const getMerchantNames = params => { return instance.get(`${SERVER_HOST}/admin/merchant/selectMerchantNames`) };